import React from "react";
import Headerp from "./../../images/GettyImages-1215515956.jpg";

function Header() {
  return (
    <div className="header-gradient">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div>
            <div className="mb-2">
              <span className="font-medium text-xs text-white"> CAREERS</span>
            </div>
            <h1 className=" font-medium text-white text-5xl mb-4">
            Healthcare solutions
            </h1>
            <p className="text-base font-normal text-white mb-5">
            Vonage connects medical experts with patients to deliver vital healthcare experiences in real-time, from anywhere and on any device. Whether it's through video, voice, SMS, chat, or social media, Vonage's cloud-based communications are
             the remedy for delivering safe and efficient telehealth services.
             </p>
             <div className="pt-16">
             <a
                  href="/"
                  className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                 Contact an expert
                </a>
             </div>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
